import axios from "axios";
import router from "@/router/routers";
// import { Notification, MessageBox,Loading } from 'element-ui'
import store from "../store";
import { getToken } from "@/utils/auth";
import Config from "@/settings";
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  // baseURL:'process.env.VUE_APP_BASE_API + '',
  // baseURL:'http://192.168.0.26:8208/',文理
  withCredentials: true,
  timeout: Config.timeout, // 请求超时时间
});
// request拦截器
service.defaults.headers["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
// service.defaults.headers["corpId"] = localStorage.getItem("cacheCorpData")
//   ? JSON.parse(localStorage.getItem("cacheCorpData")).corpId
//   : "";
//学习平台唯一识别编号
service.defaults.headers["projectCode"] = "P000";
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

let loading;
const showLoading = () => {
  loading = ELEMENT.Loading.service({
    lock: true,
    text: "数据加载中……",
    background: "#fff",
    customClass: "setloading",
  });
};
const hideLoading = () => {
  loading.close();
};

let needLoadingRequestCount = 0;
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    showLoading();
  }
  needLoadingRequestCount++;
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    hideLoading();
  }
}

let urlList = []; //防重复提交
// request拦截器
service.interceptors.request.use(
  (config) => {
    if (urlList.indexOf(config.url) == -1) {
      urlList.push(config.url);
    }
    // else{
    // 	return
    // }
    if (getToken()) {
      config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
      //  config.headers['appId'] = localStorage.getItem("appId")
      //config.headers['Authorization'] ='Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJkZXB0OmVkaXQsdXNlcjpsaXN0LHN0b3JhZ2U6YWRkLGRlcHQ6YWRkLHN0b3JhZ2U6ZWRpdCxtZW51OmRlbCxyb2xlczpkZWwsYWRtaW4sc3RvcmFnZTpsaXN0LGpvYjplZGl0LHVzZXI6ZGVsLGRlcGxveUhpc3Rvcnk6bGlzdCxzZXJ2ZXI6bGlzdCxkaWN0OmFkZCxkZXB0Omxpc3QsdGltaW5nOmFkZCxqb2I6bGlzdCxkaWN0OmRlbCxkaWN0Omxpc3QsYXBwOmxpc3Qsam9iOmFkZCxkYXRhYmFzZTpsaXN0LHRpbWluZzpsaXN0LGRlcGxveTpsaXN0LHJvbGVzOmFkZCx1c2VyTGlzdDpsaXN0LHVzZXI6YWRkLHBpY3R1cmVzOmxpc3QsbWVudTplZGl0LHRpbWluZzplZGl0LG1lbnU6bGlzdCxzdG9yYWdlOmRlbCxyb2xlczpsaXN0LG1lbnU6YWRkLGpvYjpkZWwsMSx1c2VyOmVkaXQscm9sZXM6ZWRpdCx0aW1pbmc6ZGVsLGxpdmU6bGlzdCxkaWN0OmVkaXQsc2VydmVyRGVwbG95Omxpc3QsZGVwdDpkZWwiLCJleHAiOjE1OTcxNDI2NjV9.-Fiemkaeua_2CGuaohhiIXVZBebL4QG6aCnv01ak2hgEMZMiOnZpaMMLEvJX5sY2UG-UTVJRuo5o9oBEpp5-qA'// 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["MenuId"] = router.currentRoute.meta.id;
    showFullScreenLoading();
    return config;
  },
  (error) => {
    // Do something with request error

    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    urlList.splice(urlList.indexOf(response.config.url), 1);
    tryHideFullScreenLoading();
    const code = response.status;
    if (code < 200 || code > 300) {
      ELEMENT.Notification.error({
        title: response.message,
      });
      return Promise.reject("error");
    } else {
      if (response.data.code == "-501" || response.data.code == "-502") {
        store.dispatch("LogOut").then(() => {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      } else {
        return response.data;
      }
    }
  },
  (error) => {
    tryHideFullScreenLoading();
    let code = 400;
    try {
      code = error.response.data.status;
    } catch (e) {
      if (error.toString().indexOf("Error: timeout") !== -1) {
        ELEMENT.Notification.error({
          title: "网络请求超时",
          duration: 5000,
        });
        return Promise.reject(error);
      }
    }

    if (code) {
      if (code === 401) {
        ELEMENT.MessageBox.confirm(error.response.data.message, "系统提示", {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          store.dispatch("LogOut").then(() => {
            // location.reload() // 为了重新实例化vue-router对象 避免bug
            router.push({ path: "/dashboard" });
          });
        });
      } else if (code === 403) {
        router.push({ path: "/401" });
      } else {
        const errorMsg = error.response.data.message;
        if (errorMsg !== undefined) {
          Notification.error({
            title: errorMsg,
            duration: 5000,
          });
        }
      }
    } else {
      Notification.error({
        title: "接口请求失败",
        duration: 5000,
      });
    }
    return Promise.reject(error);
  }
);
export default service;
