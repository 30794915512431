<template>
  <div class="navbar">
    <!-- <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" /> -->
    <!-- default-active="P000" 默认选中当前系统，直接写死。P000是神农，P001是学习平台。此值从/admin-auth/api/project/all接口查看
         activeMenu 选择跳转系统。
         navbar 样式需要新增弹性盒模型样式
    -->
    <el-menu
      id="navBarBox"
      default-active="P000"
      mode="horizontal"
      class="header-menu"
      @select="activeMenu"
      active-text-color="#409EFF"
    >
      <el-menu-item :id="'header-menu-' + index" :key="item.projectCode" :index="item.projectCode" v-for="(item, index) in menu">{{
        item.projectName
      }}</el-menu-item>
    </el-menu>
    <div class="right-menu">
      <!-- <template v-if="device !== 'mobile'"> -->
        <!-- <el-dropdown class="right-menu-item" @command="linkDetails">
          <span class="el-dropdown-link">
            <svg-icon icon-class="list" />
          </span>
          <el-dropdown-menu slot="dropdown" split-button type="primary">
            <el-dropdown-item v-for="items in menu" :command="items">{{
              items.projectName
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->

        <!-- <search id="header-search" class="right-menu-item" /> -->

        <!-- <el-tooltip content="源码地址" effect="dark" placement="bottom">
          <Github class="right-menu-item hover-effect" />
        </el-tooltip> -->

        <!-- <el-tooltip content="全屏缩放" effect="dark" placement="bottom">
          <screenfull id="screenfull" class="right-menu-item hover-effect" />
        </el-tooltip> -->

        <!-- <el-tooltip content="布局设置" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip> -->
      <!-- </template> -->

      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="avatar-wrapper">
          <img
            :src="
              user.avatar
                ? $store.state.public.publicImgUrl + user.avatar
                : Avatar
            "
            class="user-avatar"
          />
          <span class="user_name">{{ user.nickName }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- <span style="display: block" @click="show = true">
            <el-dropdown-item> 布局设置 </el-dropdown-item>
          </span> -->
          <router-link to="/user/center">
            <el-dropdown-item> 个人中心 </el-dropdown-item>
          </router-link>
          <span style="display: block" @click="open">
            <el-dropdown-item divided> 退出登录 </el-dropdown-item>
          </span>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <NavGuide  ref="NavGuide" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Breadcrumb from "@/components/Breadcrumb";
// import Hamburger from "@/components/Hamburger";
// import Github from "@/components/Github";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
// import Search from "@/components/HeaderSearch";
import Avatar from "@/assets/images/avatar.png";
import { projectAll } from "@/api/system/user.js";
import { unique } from "@/utils";
import NavGuide from "./NavGuide"
// import { getToken } from "@/utils/auth";
export default {
  components: {
    // Breadcrumb,
    // Hamburger,
    Screenfull,
    SizeSelect,
    // Search,
    // Github,
    NavGuide
  },
  data() {
    return {
      Avatar: Avatar,
      dialogVisible: false,
      menu: [],
      projectCode: null,
    };
  },
  computed: {
    ...mapGetters(["sidebar", "device", "user", "baseApi"]),
    show: {
      get() {
        return this.$store.state.settings.showSettings;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val,
        });
      },
    },
  },
  mounted() {
    projectAll().then((res) => {
      this.menu = res;
      this.$nextTick(() => {
        const id = document.querySelector('#navBarBox .el-menu-item.is-active').getAttribute('id') || 'header-menu-0'
        this.$refs.NavGuide.showGuide('#' + id)
      })
    });
  },
  methods: {
    // toggleSideBar() {
    // this.$store.dispatch("app/toggleSideBar");
    // },
    open() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.logout();
      });
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        location.reload();
      });
    },
    linkDetails(e) {
      //系统跳转 并且过滤当前神农系统
      if (!e.isJump && e.projectCode != "P000") {
        let url = e.projectUrl + "/login?projectCode=" + e.projectCode;
        window.open(url);
      }
    },
    // 系统跳转
    activeMenu(code) {
      let selectSystem = this.menu.find(item => {
        return item.projectCode == code
      })
      window.location.href = selectSystem.projectUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  // height: 50px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .header-menu{
    padding: 4px 14px;
    flex: 1;
    border-bottom: none !important;
    .el-menu-item{
      padding: 18px 20px;
      color: rgba(0,0,0,0.70);
      font-size: 14px;
      font-weight: 400;
      height: initial;
      line-height: initial;
      border-bottom-width: 3px
    }
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    // line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
          width: 24px;
          height: 24px;
          border-radius: 10px;
        }

        .user_name {
          margin-left: 6px;
          display: inline-block;
          vertical-align: middle;
          font-size: 13px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          // position: absolute;
          // right: -20px;
          // top: 25px;
          margin-left: 8px;
          display: inline-block;
          vertical-align: middle;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
