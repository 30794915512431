
const order = {
  state: {
    orderTypeList:[
      {label:'全部',value:''},
      {label:'普通课程订单',value:0},
      {label:'优惠券订单',value:1},
    ],
    statusList:[
      // {label:'全部',value:''},
      {label:'待支付',value:0},
      {label:'待发货',value:1},
      {label:'待收货',value:2},
      {label:'退款中',value:3},
      {label:'已退款',value:4},
      {label:'已完成',value:5},
      {label:'已取消(手动)',value:6},
      {label:'已取消',value:7},
    ],
    payTypeList:[
      {label:'全部',value:''},
      {label:'微信',value:0},
      {label:'支付宝',value:1},
      {label:'银行卡',value:2},
    ],
    sourceList:[
      {label:'全部',value:''},
      {label:'H5',value:1},
      {label:'小程序',value:2},
      {label:'安卓',value:3},
      {label:'IOS',value:4},
    ],
    subjectTypes: [
      {
        value: '1',
        label: '单选题'
      },
      {
        value: '2',
        label: '多选题'
      },
      {
        value: '3',
        label: '填空题'
      },
      {
        value: '4',
        label: '判断题'
      }
    ],
    fileTypes: [
      { value: '1', label: '图片' },
      { value: '2', label: '视频' },
      { value: '3', label: '音频' }
    ],
    editorConfig:[
        'head',  // 标题
        'bold',  // 粗体
        'fontSize',  // 字号
        'fontName',  // 字体
        'italic',  // 斜体
        'underline',  // 下划线
        'strikeThrough',  // 删除线
        'foreColor',  // 文字颜色
        'backColor',  // 背景颜色
        'link',  // 插入链接
        'list',  // 列表
        'justify',  // 对齐方式
        'quote',  // 引用
        // 'emoticon',  // 表情
        // 'image',  // 插入图片
        // 'table',  // 表格
        // 'video',  // 插入视频
        'code',  // 插入代码
        'undo',  // 撤销
        'redo'  // 重复
    ],
    pickerOptions: {
      shortcuts: [{
        text: '最近一周',
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit('pick', [start, end]);
        }
      }, {
        text: '最近一个月',
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit('pick', [start, end]);
        }
      }]
    },
    courierValidate:[
      {
        "code": "SF",
        "id": 1,
        "name": "顺丰",
        "test": "/^SF\d{13}$/"
      },
      {
        "code": "HTKY",
        "id": 2,
        "name": "百世",
        "test": "/^((A|B|D|E|S|s)[0-9]{12})|(BXA[0-9]{10})|(K8[0-9]{11})|(02[0-9]{11})|(000[0-9]{10})|(C0000[0-9]{8})|((21|22|23|24|25|27|28|29|31|32|33|35|61|63|16|66)[0-9]{10})|((50|51)[0-9]{12})|7[0-9]{13}|6[0-9]{13}|8[0-9]{11}|(58|56)[0-9]{14}|6[0-9]{8}$/"
      },
      {
        "code": "ZTO",
        "id": 3,
        "name": "中通",
        "test": "^((010|768|765|778|779|719|828|618|680|518|688|880|660|805|988|628|205|717|718|728|738|761|762|763|701|757|751|359|358|100|200|118|128|689|738|528|852)[0-9]{9})$|^((5711|2008|2009|2010|2013)[0-9]{8})$|^((91|92|93|94|95|98|36|68|39|50|53|37)[0-9]{10})$|^(4)[0-9]{11}$|^(90)[0-9]{10}$|^(120)[0-9]{9}$|^(780)[0-9]{9}$|^(881)[0-9]{9}$|^(882|885)[0-9]{9}$|^(54|55|56)[0-9]{10}$|^(960)[0-9]{9}$|^(665|666)[0-9]{9}$|^(63)[0-9]{10}$|^(64)[0-9]{10}$|^(72)[0-9]{10}$|^2[1-9][0-9]{10}$"
      },
      {
        "code": "STO",
        "id": 4,
        "name": "申通",
        "test": "^(268|888|588|688|368|468|568|668|768|868|968)[0-9]{9}$|^(11|22|40|268|888|588|688|368|468|568|668|768|868|968)[0-9]{10}$|^(STO)[0-9]{10}$|^(33)[0-9]{11}$|^(4)[0-9]{12}$|^(55)[0-9]{11}$|^(66)[0-9]{11}$|^(77)[0-9]{11}$|^(88)[0-9]{11}$|^(99)[0-9]{11}$"
      },
      {
        "code": "YTO",
        "id": 5,
        "name": "圆通",
        "test": "^[A-Za-z0-9]{2}[0-9]{10}$|^[A-Za-z0-9]{2}[0-9]{8}$|^[6-9][0-9]{17}$|^[DD]{2}[8-9][0-9]{15}$|^[Y][0-9]{12}$"
      },
      {
        "code": "YD",
        "id": 6,
        "name": "韵达",
        "test": "^(10|11|12|13|14|15|16|17|19|18|50|55|58|80|88|66|31|77|39)[0-9]{11}$|^[0-9]{13}$"
      },
      {
        "code": "YZPY",
        "id": 7,
        "name": "邮政",
        "test": "^([GA]|[KQ]|[PH]){2}[0-9]{9}([2-5][0-9]|[1][1-9]|[6][0-5])$|^[99]{2}[0-9]{11}$|^[96]{2}[0-9]{11}$|^[98]{2}[0-9]{11}$"
      },
      {
        "code": "EMS",
        "id": 8,
        "name": "EMS",
        "test": "^[A-Z]{2}[0-9]{9}[A-Z]{2}$|^(10|11)[0-9]{11}$|^(50|51)[0-9]{11}$|^(95|97)[0-9]{11}$"
      },
      {
        "code": " HHTT",
        "id": 9,
        "name": "天天",
        "test": "(66|77|88|(5(5|6|8)))\d{10}|(99(5|8))\d{9}|TT(66|88|99|(5(6|7)))\d{11}"
      },
      {
        "code": "JD",
        "id": 10,
        "name": "京东",
        "test": "/^(?=.*\d)[0-9A-Za-z]{10,20}$/"
      },
      {
        "code": "UC",
        "id": 11,
        "name": "优速",
        "test": "/^(?=.*\d)[0-9A-Za-z]{10,20}$/"
      },
      {
        "code": "DBL",
        "id": 12,
        "name": "德邦",
        "test": "/^(?=.*\d)[0-9A-Za-z]{10,20}$/"
      },
      {
        "code": "TNT",
        "id": 13,
        "name": "TNT",
        "test": "/^(?=.*\d)[0-9A-Za-z]{10,20}$/"
      },
      {
        "code": "UPS",
        "id": 14,
        "name": "UPS",
        "test": "/^(?=.*\d)[0-9A-Za-z]{10,20}$/"
      },
      {
        "code": "DHL",
        "id": 15,
        "name": "DHL",
        "test": "/^(?=.*\d)[0-9A-Za-z]{10,20}$/"
      },
    ],
		uploadImgUrl:process.env.VUE_APP_BASE_API+'common-server/aliFile/upLoadFileNoSecret',
		uploadFileUrl:process.env.VUE_APP_BASE_API+'activitys/pc/tiKu/excelInSys',//导入题目
		uploadVideo:'http://121.199.5.67:8210/tencentNew/uploadVoid',//上传视频素材
		uploadUser:process.env.VUE_APP_BASE_API+'order/pc/coupon/addCouponQuota',//优惠券导入用户
		uploadOrder:process.env.VUE_APP_BASE_API+'order/pc/order/importOrders',//导入订单
		uploadDisease:process.env.VUE_APP_BASE_API+'activitys/pc/medical/excelTypesInSys',//导入医案病症
		excelSignRoster:process.env.VUE_APP_BASE_API+'activitys/pc/activityManager/excelSignRoster',//年会活动导入签到名单
  }
}

export default order
