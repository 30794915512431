const getters = {
  deployUploadApi: (state) => state.api.deployUploadApi,
  databaseUploadApi: (state) => state.api.databaseUploadApi,
  socketApi: (state) => state.api.socketApi,
  imagesUploadApi: (state) => state.api.imagesUploadApi,
  baseApi: (state) => state.api.baseApi,
  fileUploadApi: (state) => state.api.fileUploadApi,
  updateAvatarApi: (state) => state.api.updateAvatarApi,
  qiNiuUploadApi: (state) => state.api.qiNiuUploadApi,
  sqlApi: (state) => state.api.sqlApi,
  swaggerApi: (state) => state.api.swaggerApi,

  size: (state) => state.app.size,
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,

  token: (state) => state.user.token,
  roles: (state) => state.user.roles,
  user: (state) => state.user.user,
  loadMenus: (state) => state.user.loadMenus,

  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,

  permission_routers: (state) => state.permission.routers,
  addRouters: (state) => state.permission.addRouters,

  orderTypeList: (state) => state.publiced.orderTypeList, // (zejun)
  publicImgUrl: (state) => state.public.publicImgUrl, // (zejun 公共图片 域名)
  corpList: (state) => state.public.corpList, //获取主体

  statusList: (state) => state.publiced.statusList,
  payTypeList: (state) => state.publiced.payTypeList,
  sourceList: (state) => state.publiced.sourceList,
  pickerOptions: (state) => state.publiced.pickerOptions,
  uploadImgUrl: (state) => state.publiced.uploadImgUrl,
  uploadFileUrl: (state) => state.publiced.uploadFileUrl,
  subjectTypes: (state) => state.publiced.subjectTypes,
  uploadMaterial: (state) => state.publiced.uploadMaterial,
  uploadMaterial2: (state) => state.publiced.uploadMaterial2,
  courierValidate: (state) => state.publiced.courierValidate,
  uploadVideo: (state) => state.publiced.uploadVideo,
  fileTypes: (state) => state.publiced.fileTypes,
  uploadUser: (state) => state.publiced.uploadUser,
  uploadOrder: (state) => state.publiced.uploadOrder,
  uploadDisease: (state) => state.publiced.uploadDisease,
  editorConfig: (state) => state.publiced.editorConfig,
  excelSignRoster: (state) => state.publiced.excelSignRoster,
};
export default getters;
