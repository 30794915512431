const VUE_APP_VERSION = require("../package.json").version;
//对比版本号是否一致 更新缓存并强制刷新
if (VUE_APP_VERSION != localStorage.getItem("AppVersion")) {
  window.localStorage.setItem("AppVersion", VUE_APP_VERSION);
  window.location.reload(true);
}

import Vue from "vue";

import Cookies from "js-cookie";

import "normalize.css/normalize.css";

// import Element from 'element-ui'
// import "element-ui/lib/theme-chalk/index.css"
//

// 数据字典
import dict from "./components/Dict";

// 权限指令
import permission from "./components/Permission";
import "./assets/styles/element-variables.modules.scss";
// global css
import "./assets/styles/index.scss";
// // 代码高亮
// import VueHighlightJS from 'vue-highlightjs'
// import 'highlight.js/styles/atom-one-dark.css'

import App from "./App";
import store from "./store";
import router from "./router/routers";

import "./assets/icons"; // icon
import "./router/index"; // permission control

import VueClipboard from "vue-clipboard2";
// Vue.use(VueHighlightJS)
Vue.use(VueClipboard).use(permission).use(dict);
// Vue.use(Element, {
//   size: Cookies.get('size') || 'small' // set element-ui default size
// })
// store.dispatch("GetCorpList");
import ElSTable from "./components/ElSTable";
Vue.use(ElSTable);
Vue.prototype.$ELEMENT = { size: Cookies.get("size") || "small" };
Vue.prototype.openPage = function (name, par) {
  this.$router.push({ name: name, query: par });
};
Vue.prototype.goBack = function () {
  this.$router.back();
};

Vue.prototype.$alertMsgBox = function alert() {
  return this.$prompt("您确定要删除此数据吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    closeOnClickModal: false,
    inputPlaceholder: "请输入DELETE，进行删除",
    inputPattern: /^[D][E][L][E][T][E]+$/,
    inputErrorMessage: "请输入DELETE，进行删除",
  }).then(() => {});
};
Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
