import { getPublicImgUrl } from "@/api/upload/upload";
import { getDingCorp } from "@/api/login";

const state = {
  publicImgUrl: "",
  corpList: [],
};
const mutations = {
  SETTING_PUBLIC_IMGURL(state, data) {
    state.publicImgUrl = data;
  },
  CORP_LIST(state, data) {
    state.corpList = data;
  },
};
const actions = {
  // 获取图片公共 域名
  SetPublicImgUrl({ commit }) {
    return new Promise((resolve, reject) => {
      getPublicImgUrl().then((res) => {
        if (res.code == 200) {
          commit("SETTING_PUBLIC_IMGURL", res.data.filePath);
        } else {
          console.log(res.message);
        }
      });
    });
  },
  // 获取主体
  GetCorpList({ commit }) {
    return new Promise((resolve, reject) => {
      getDingCorp().then((res) => {
        if (res.code == 200) {
          commit("CORP_LIST", res.data);
        } else {
          console.log(res.message);
        }
      });
    });
  },
};

export default {
  namespaced: false,
  state,
  mutations,
  actions,
};
