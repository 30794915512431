// 顶部导航栏菜单 引导点击
<template>
  
</template>
<script>
import { mapGetters } from "vuex";
import introJs from 'intro.js' // introjs库
import 'intro.js/introjs.css' // introjs默认css样式
import 'intro.js/themes/introjs-modern.css' // introjs主题

export default {
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    showGuide(selector) {
      const isGuide = localStorage.getItem('nav-guide-' + this.user.id)
      if (isGuide) {
        return
      }
      this.$nextTick(() => {
        const intro = new introJs();
        setTimeout(() => {
          intro.setOptions({
            // prevLabel: '上一步',
            // nextLabel: '下一步',
            // skipLabel: '跳过',
            helperElementPadding: 0,
            exitOnOverlayClick: false,  // 点击遮罩 是否关闭
            highlightClass: 'custom_highlight',// 提示框自定义样式
            tooltipClass: 'custom_tooltip',// 提示框自定义样式
            doneLabel: '知道了',
            showBullets: false,
            steps: [
              {
                element: selector, // 元素
                title: '平台切换',
                intro: '点击需要的系统进行切换选择',
                position: 'right' // 位置
              }
            ]
          })
          intro.start().then(() => {
            // 修改高亮区域的阴影边框
            document.querySelector('.introjs-helperLayer.custom_highlight').style.boxShadow = 'rgba(33, 33, 33, 0.8) 0px 0px 1px 0, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px'
            // 异常提示框的指示箭头
            document.querySelector('.custom_tooltip .introjs-arrow').style.display = 'none'
            localStorage.setItem('nav-guide-' + this.user.id, 1)
          })
        })
      })
    }
  }
}
</script>

<style lang="scss">
.custom_tooltip  {
  color: #303030;
  font-size: 13px;
  background-color: #fff;
  border-radius: 4px;

  .introjs-tooltip-title {
    color: #19191a;
    font-size: 14px;
    font-weight: 600;
  }
  .introjs-skipbutton {
    display: none;
  }
  .introjs-tooltipbuttons {
    border: none
  }
  .introjs-button {
    color: #fff;
    font-size: 14px;
    background-color: rgba(22, 119, 255, 1);
    border-radius: 2px;
    border: none;
    &:focus,&:hover,&:active {
      background-color: rgba(22, 119, 255, .8);
      border-radius: 2px;
      border: none
    }
  }
  }
</style>