<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
.setloading{
  opacity: 0.7;
}
.avater{
	width: 44px;
	height: 44px;
	border-radius: 50%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.editor{
  max-width: 800px;
  position: relative;
}
.editor .btn{
  position: absolute;
  top: 6px;
  right: 10px;
}
.addQuestion .w-e-text-container{
    min-height: 150px !important;
    max-height: 300px !important;
    height: auto !important;
    overflow-y: auto;
}
.w-e-text{
  min-height: 150px !important;
  overflow: hidden !important;
  line-height: 24px;
}
li{
  list-style: none;
  padding: 0;
  margin: 0;
}
.fileBox{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px dotted #ccc;
  border-radius: 3px;
  height: 100px;
  width: 100px;
  font-size: 20px;
  color: #999;
  cursor: pointer;
  background: #fafafa;
  overflow: hidden;
}
.fileBox:hover{
  border-color: #46a6ff;
}
.courseList{
  margin: 10px 0;
}
.app-main{
  padding-bottom: 50px;
}
.w-e-text{
  overflow: scroll !important;
}
.el-icon-circle-close{
  color: #fff;
}
</style>
