import request from "@/utils/request";
export function login(username, password, code, uuid, corpId) {
  return request({
    url: "/admin-auth/auth/login",
    method: "post",
    data: {
      username,
      password,
      code,
      uuid,
      corpId,
    },
  });
}

export function getInfo(data) {
  return request({
    url: "/admin-auth/auth/info",
    method: "get",
    params: data,
  });
}

export function getCodeImg() {
  return request({
    url: "/admin-auth/auth/code",
    method: "get",
  });
}

export function logout() {
  return request({
    url: "/admin-auth/auth/logout",
    method: "delete",
  });
}
export function dingLogin(data) {
  return request({
    url: "/admin-auth/auth/ding-login",
    method: "get",
    params: data,
  });
}

export function getDingCorp() {
  return request({
    url: "/admin-auth/auth/no-auth/get-ding-corp",
    method: "get",
  });
}
